import React from 'react'
import { createTheme, MuiThemeProvider, useTheme } from '@material-ui/core/styles'

import { ISection } from '@/shared/models/Section'
import { ITheme, overridesMuiButton as overridesMuiButton, overridesMuiTab } from '@/shared/styles/MuiTheme'
import { useClub } from 'contexts/club'

interface IProps {
    section: ISection;
    children: React.ReactNode;
}

export default function SectionThemeProvider({
    section,
    children,
}: IProps): JSX.Element {
    const { modes } = useClub()
    const theme: ITheme = useTheme()
    const currentSectionMode = modes.find(mode => mode.type === section.mode.type)
    // NOTE section can have custom colors which we set manually in DB.
    // We need to replace that color in mode only for this specific section
    // So we take colors of section mode M1, and then merge mode object with section's mode object to be sure if section hase some custom color - it will replace in mode
    const newThemeProps = { ...currentSectionMode, ...section.mode ? {...section.mode} : {}}

    const sectionTheme = createTheme(theme, { //NOTE deep merge theme with new props
		props: {
            sectionMode: newThemeProps
        },
        palette: {
			primary: {
				light: newThemeProps.accent,
				main: newThemeProps.accent,
				dark: newThemeProps.accent,
			},
			secondary: {
				light: newThemeProps.text_background,
				main: newThemeProps.text_background,
				dark: newThemeProps.text_background,
			},
            text: {
                default: newThemeProps.text_background
            }
		},
        overrides: {
			MuiButton: {
                ...overridesMuiButton(newThemeProps)
			},
            MuiTabs: {
                ...overridesMuiTab(newThemeProps)
            },
            MuiPaper: {
                root: {
                    backgroundColor: newThemeProps.card
                }
            },
            MuiOutlinedInput: {
                notchedOutline: {
                    borderColor: newThemeProps.text_background
                }
            },
            MuiSelect: {
                icon: {
                    color: newThemeProps.text_background
                }
            },
            MuiInputBase: {
                input: {
                    color: newThemeProps.text_background
                },
            },
            MuiListItem: {
                root: {
                    color: newThemeProps.text_background
                }
            },
            MuiFormLabel: {
                root: {
                    color: newThemeProps.text_background
                }
            },
            MuiPaginationItem: {
                root: {
                    color: newThemeProps.text_background
                }
            }
        }
    })

    return (
        <MuiThemeProvider theme={sectionTheme}>
            {children}
        </MuiThemeProvider>
    )
}