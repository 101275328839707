import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Image from 'next/image';
import { ISection, ISectionBackgroundType, ISectionOrigin, SectionType } from '@/shared/models/Section'
import { ITheme } from '@/shared/styles/MuiTheme'
import { getImageBackgroundUrls } from '../utils'
import { usePage } from 'contexts/page'
import { useContainerHeight } from 'contexts/containerHeight'
import useElementSize from '@/shared/hooks/useElementSize'
import { debounce } from '@/shared/utils/debounce'

const useStyles = makeStyles((theme: ITheme) => ({
    section: {
        backgroundColor: theme.props.sectionMode.background,
        position: 'relative',
        zIndex: 0,
    },
    backgroundImage: {
        position: 'absolute',
        zIndex: -1,
        inset: 0,
        maxHeight: '100%',
        overflowY: 'hidden',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    sectionGutter: {
        padding: theme.spacing(7, 4),
    }
}))

interface IProps {
    section: ISection
    children: React.ReactNode
    clubId?: number
}

export default function SectionBackground({ section, children, clubId }: IProps): JSX.Element {
    const { type, url } = section?.customize?.background || {}
    const [images, setImages] = useState(null)
    const { sections } = usePage()
    const [customHeight, setCustomHeight] = useState<number | undefined>()
    const containerHeight = useContainerHeight()
    const [sectionRef, { height: sectionHeight }] = useElementSize()
    const debounceSetHeight = useCallback(
        debounce((value) => {
          setCustomHeight(value)
        }, 200),
        [setCustomHeight]
    )

    useEffect(() => {
        const images = (type === ISectionBackgroundType.IMAGE && url && getImageBackgroundUrls(url, clubId)) || null
        setImages(images)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    useEffect(() => {
        if(containerHeight >= sectionHeight && sections.length === 1 && sections[0]?.origin === ISectionOrigin.CUSTOM && containerHeight < 500) {
            debounceSetHeight(containerHeight)
        } else {
            debounceSetHeight(undefined)
        }
    }, [sectionHeight, containerHeight, sections])
    
    const classes = useStyles({ height: customHeight })
    const child = (
        <>
            {type === ISectionBackgroundType.IMAGE && images && (
                <div className={classes.backgroundImage}>
                    <picture>
                        <source srcSet={images.webp.url} type={images.webp.type} />
                        <source srcSet={images.default.url} type={images.default.type} />
                        <Image
                            src={images.default.url}
                            alt={section.name || ''}
                            layout='fill'
                            loader={({ src }) => `${src}?q=75`}
                        />
                    </picture>
                </div>
            )}

            <Container className={section?.system_section?.type !== SectionType.DIVIDER ? classes.sectionGutter : ''} maxWidth='lg'>{children}</Container>
        </>
    )
    if(section.origin === ISectionOrigin.CUSTOM) {
        return (
            <section ref={sectionRef} style={{ minHeight: customHeight }} className={classes.section}>{child}</section>
        )
    }
    return (
        <main ref={sectionRef} style={{ minHeight: customHeight }} className={classes.section}>{child}</main>
    )
}
