import { ISection } from '@/shared/models/Section'
import SectionThemeProvider from 'components/ThemeProvider/SectionThemeProvider'
import SectionBackground from './SectionBackground'

interface IProps {
    section: ISection;
    children: React.ReactNode;
    clubId?: number;
}

export default function SectionWrapper({
    section,
    children,
    clubId
}: IProps): JSX.Element {
    return (
        <SectionThemeProvider section={section}>
            <SectionBackground section={section} clubId={clubId}>
                {children}
            </SectionBackground>
        </SectionThemeProvider>
    )
}